//
// Home features
//

// Choose your overall home feature cards layout
.homeFeatures {
  @include cards-layout-prefab (
    $number-of-cards: 4,
    $prefab: 2
  );

  // Choose your card style for home feature cards
  .homeFeature {
    @include card;
    @include card-basic;
    // @include card-text-overlay;
    &.homeBox1 {
      @include card (
        $card-image-background-colour: $_blue,
        // $card-image-background-colour: $_dark-grey,
        $card-heading-colour: $_blue,
        $card-hover-heading-colour: $_blue,
        // $card-details-padding: $spacer,
        // $card-details-background-colour: rgba($white, 0.75),
        // $card-hover-details-background-colour: rgba($white, 0.85)

      );
      @include card-basic;
      // @include card-text-overlay;
    }
    &.homeBox2 {
      @include card (
        $card-image-background-colour: $_orange,
        // $card-image-background-colour: $_dark-grey,
        $card-heading-colour: $_orange,
        $card-hover-heading-colour: $_orange,
        // $card-details-padding: $spacer,
        // $card-details-background-colour: rgba($white, 0.75),
        // $card-hover-details-background-colour: rgba($white, 0.85)
      );
      @include card-basic;
      // @include card-text-overlay;
    }
    &.homeBox3 {
      @include card (
        $card-image-background-colour: $_yellow,
        // $card-image-background-colour: $_dark-grey,
        $card-heading-colour: darken($_yellow, 2.5%),
        $card-hover-heading-colour: darken($_yellow, 2.5%),
        // $card-details-padding: $spacer,
        // $card-details-background-colour: rgba($white, 0.75),
        // $card-hover-details-background-colour: rgba($white, 0.85)
      );
      @include card-basic;
      // @include card-text-overlay;
    }
    &.homeBox4 {
      @include card (
        $card-image-background-colour: $_turquoise,
        // $card-image-background-colour: $_dark-grey,
        $card-heading-colour: $_turquoise,
        $card-hover-heading-colour: $_turquoise,
        // $card-details-padding: $spacer,
        // $card-details-background-colour: rgba($white, 0.75),
        // $card-hover-details-background-colour: rgba($white, 0.85)
      );
      @include card-basic;
      // @include card-text-overlay;
    }
  }
}

// Alternatively, you can target specific features rather than styling them all
// If you do this, you will need to target them ALL uniquely, so:
// .homeBox1 {...}, .homeBox2 {...}, .homeBox3 {...} etc.


//
// Home feeds
//

// Choose your overall home feeds layout
@include home-feeds-prefab (
  $number-of-feeds: 2,
  $prefab: 2
);

// Specify a card layout for each individual feed
.homeFeedBox1 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 1,
    $prefab: 1
  );
  .feedItem {
    @include card (
      $card-image-background-colour: $_blue,
      $card-hover-heading-colour: $_blue
    );
    @include card-basic;
  }
}

.homeFeedBox2 .feedList {
  @include cards-layout-prefab (
    $number-of-cards: 3,
    $prefab: 12
  );
  .feedItem {
    @include card (
      $card-image-background-colour: $_turquoise,
      $card-hover-heading-colour: $_turquoise,
      $card-details-padding: $spacer
    );
    @include card-side-by-side;
  }
}


//
// Listed posts
//

// Choose your card style for listed post cards
// (not products, search result or embedded map item)
.listedPost:not(.listedProduct):not(.listedSearchResult):not(.listedMapItem) {
  @include card (
    $card-image-background-colour: $_blue,
    $card-heading-colour: $_blue,
    $card-hover-heading-colour: $_blue,
  );
  @include card-basic;
}


//
// Listed products
//

// If these are exactly the same as your regular 'listed posts' above
// you can remove the ':not(.listedProduct)' selector above and squash this
// together into more efficient CSS output
.listedProduct {
  @include card;
  @include card-basic;
}
