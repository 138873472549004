// Effra - Font import
@font-face {
  font-family: 'Effra-Bold';
  src: url($assets-path + 'Effra-Bold.woff2') format('woff2'),
       url($assets-path + 'Effra-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Effra-Regular';
  src: url($assets-path + 'Effra-Regular.woff2') format('woff2'),
       url($assets-path + 'Effra-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'llamau-type';
  src: url($assets-path + 'llamau-type-2020.otf') format('opentype');
}


// Blue button - pseudo
a.cta-button, a.addToCalender, a.mapLink {
  background-image: url($assets-path + "blue-button.png");
  background-size: 100.5% 100.5%;
  background-position: center;
  &:hover {
    background-image: url($assets-path + "blue-button-hover.png");
  }
}


// Yellow button - pseudo
.callToActionBtn, .submit, .callToAction, .customFormSubmit,
.cta-button.donate, .button.donate, button.donate, .backToTop, .loadMore, a.eventBookingBtn {
  background-color: transparent;
  background-image: url($assets-path + "orange-button.png");
  background-size: 100.5% 100.5%;
  background-position: center;
  &:hover {
    background-color: transparent;
    background-image: url($assets-path + "orange-button-hover.png");
  }
}

// Orange button - pseudo
.carouselSlideReadMore {
  margin-top: $spacer * 1.5;
  background-image: url($assets-path + "orange-button.png");
  background-size: 100.5% 100.5%;
  background-position: center;
  &:hover {
    background-image: url($assets-path + "orange-button-hover.png");
  }
}


// Nav + burger nav - pseudo
.pageHeader:after {
  content: '';
  display: block;
  position: absolute;
  background-image: url($assets-path + "nav-grey.png");
  width: 100%;
  height: 100%;
  bottom: -8px;
  left: 0;
  background-size: 100% 65px;
  background-position: bottom center;
  background-repeat: no-repeat;
  z-index: -1;
  @media (max-width: map-get($breakpoints, lg)) {
    bottom: -8px;
    background-size: 100% 55px;
  }
  @media (max-width: map-get($breakpoints, md)) {
    background-image: url($assets-path + "mobile-nav-grey.png");
  }
}


// Submenu - Desktop
@media (min-width: map-get($breakpoints, lg) + 1px) {
  ul.subMenu {
    background-color: transparent;
    padding: 0.25em 0.25em 0.5rem;
    background-image: url($assets-path + "submenu-grey.png");
    background-size: 100% 100%;
  }
}


// Header sticky - logo
@media (max-width: map-get($breakpoints, lg)) {
  .pageHeader.sticky .mainLogo {
    width: $logo-mobile-width;
    height: $logo-mobile-height;
  }
}


// Carousel Text
.carouselSlideHeading {
  font-family: 'llamau-type';
  @media (min-width: map-get($breakpoints, lg) +1px) {
    font-size: $font-size-h1 * 1.25;
  }
}

// Carousel - mobile layour
@media (max-width: map-get($breakpoints, lg)) {
  .carouselSlideDetail { text-align: center; }
  .carouselSlideReadMore { margin: 1.5rem auto; }

}


// Carousel - pseudo
.carouselSlideTitle:after {
  background-image: url( $assets-path + "carousel-brush.png");
  background-size: 100.5% 100.5%;
  background-position: center;
}


// Carousel mobile - pseudo
@media (max-width: map-get($breakpoints, lg)) {
  body.homepage .carouselDetailWrapper { position: relative; }
  body.homepage .carouselDetailWrapper:before {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 2rem;
    background-image: url($assets-path + "carousel-mobile-white.png");
    background-size: 102.5% 102.5%;
    background-position: center;
    transform: translateY(1%);
  }
}


// Carousel Controls
.carouselControlNext:before { content: '\f178'; }
.carouselControlPrev:before { content: '\f177'; }


// Carousel Controls - hover
@media (min-width: map-get($breakpoints, lg) +1px) {
  .carouselControls a:before { color: rgba($white, 0.55); }
  .carousel:hover .carouselControls a:before { color: $white; }
}


// Home Intro - pseudo
.homeIntroWrapper {
  background-image: url( $assets-path + "home-intro.png");
  background-size: 100.5% 100.5%;
  background-position: center;
  @media (min-width: map-get($breakpoints, lg) +1px) {
    margin-top: -$spacer * 3;
  }
}


// Home Intro - button
.homeIntro a.cta-button {
  font-size: 1rem;
  text-decoration: none;
  margin-top: $spacer;
}


// Home Feature Intro Title - pseudo
h2.homeFeaturesTitle {
  @include heading-underline(
    $colour: transparent,
    $width: 3em,
    $height: .4em
  );
  &:after {
    background-image: url($assets-path + "blue-underline.png");
    background-size: 100% 100%;
  }
}

// Home Features - Desktop Layout
@media (min-width: map-get($breakpoints, lg) + 1px) {
  .homeFeatures {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(4, 1fr);


    .homeFeature {
      min-width: auto;

      &.homeBox1 {
        grid-column: 1/6;
        grid-row: 1/5;
      }

      &.homeBox2 {
        grid-column: 6/8;
        grid-row: 1/3;
      }

      &.homeBox3 {
        grid-column: 8/10;
        grid-row: 1/3;
      }

      &.homeBox4 {
        grid-column: 6/10;
        grid-row: 3/5;
      }
    }
  }

  // // Fix for IE - the above layout is impossible because of its terrible grid implementation
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .homeFeatures {
      display: flex;
      .homeFeature {
        min-width: $card-min-width;
      }
    }
  }

}


// Home Feeds Title - pseudo
h3.feedsTitle {
  @include heading-underline(
    $colour: transparent,
    $width: 3em,
    $height: .4em
  );
  &:after {
    background-image: url($assets-path + "yellow-underline.png");
    background-size: 100% 100%;
  }
}


// Feeds - pseudo
.homeFeedsWrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background-image: url($assets-path + "feeds-background.png");
  background-size: 102.5% 100%;
  background-position: top center;
  transform: translateY(-50%);
}


// Feed order
.homeFeed {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  h3.feedsTitle { order: 1; }
  ul.feedList { order: 2; }
  > a.cta-button { order: 3; }
}


// Feeds Read more button - layout
.homeFeed > a.cta-button {
  margin: $card-gap-width * 0.5;
}


// Feed - Card side by side layout
@media (min-width: map-get($breakpoints, md) + 1px) {
  .homeFeedBox2 .feedList .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    padding-top: 0;
    padding-right: 0;
  }
}


// Header + Body Social icons + Share icons
.mainCallToAction .socialIcons a,
.socialIcons a, .shareList a {
  border: 1.5px solid $social-icons-colour;
  &:hover { background-color: $body-colour; }
  &:before { margin: 0 auto; }
}

// Mobile nav - social icons
.menuMain ul.socialIcons a {
  border: 1.5px solid text-contrast($nav-normal-mobile-background-colour);
  &:hover:before { color: $_light-grey; }
}


// Feeds - pseudo
.pageFooterWrapper { position: relative; }
.pageFooterWrapper:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4rem;
  background-image: url($assets-path + "footer-turquoise.png");
  background-size: 102.5% 100%;
  background-position: top center;
  transform: translateY(-50%);
}


// Footer - pseudo
.groupSignUpBtn {
  background-image: url($assets-path + "orange-button.png");
  background-size: 100.5% 100.5%;
  &:hover {
    background-image: url($assets-path + "orange-button-hover.png");
  }
}

// Footer - Headings
.footerBox > h3 {
  margin-bottom: $spacer * 1.5;
}

// Footer - social icons
.footerBox ul.socialIcons a {
  text-decoration: none !important;
  border: 1.5px solid $social-icons-footer-colour;
  &:hover { background-color: $social-icons-footer-colour; }
}


// Bespoke from Custom styles
ul.listOfListing {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  li {
    padding: 20px;
    margin: 0 2% 20px 0;
    list-style: none;
    background-color: $_blue;
    min-height: 180px;
    width: 100%;
    &:first-child { margin-left: -20px; }
    a {
      padding: 0;
      color: #fff;
      font-size: 1.2em;
    }
  }
  @media (max-width: map-get($breakpoints, md)) {
    flex-direction: column;
    li {
      margin: 0 0 20px -20px;
      min-height: 100px;
    }
  }
}


// Post - pseudo
body.page-has-banner:not(.homepage):not(.subsite) .carouselSlide:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2rem;
  background-image: url($assets-path + "post-white.png");
  background-size: 102.5% 100%;
  background-position: top center;
  transform: translateY(50%);
}


// Sidebar - pseudo
.post .contentBlock .postAside>*:not(.appealWidgets) {
  padding: $spacer * 1.75;
  background-image: url($assets-path + "sidebar-yellow.png");
  background-size: 100% 100%;
}


// Sidebar - layout
.associated > h2 { margin-bottom: 0.25em; }
.associated:not(.associatedBox) li { padding: .5em 0; }


// Donate form - layout
.formDonatePage .formQuestion.donationPeriod {
  margin-top: $spacer * 1.5;
  border-radius: $border-radius $border-radius 0 0;
}

// Donor list - pseudo
.donorsList {
  background-color: rgba($_light-grey, 0.0125);
  padding: $spacer * 2;
  background-image: url($assets-path + "donorlist-grey.png");
  background-size: 100% 100%;
  @media (max-width: map-get($breakpoints, md)) {
    background-image: url($assets-path + "sidebar-grey.png");
  }
}


// Quick exit button
.quick-exit {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: $btn-padding-y $btn-padding-x;
  border-radius: $border-radius 0 0 0;
  background-color: $_blue;
  color: $white;
  text-transform: $btn-text-transform;
  font-weight: $btn-font-weight;
  font-size: $btn-font-size;
  @media (max-width: map-get($breakpoints, md)) {
    width: 100%;
    text-align: center;
    border-radius: 0;
  }
  &:hover {
    color: $white;
    background-color: darken($_blue, 10%);
    text-decoration: none;
  }
  &:before {
    font-size: 15px;
    content: '\f08e';
    margin-right: 8px;
    font-family: $icon-font-family;
  }
}


// FAQs
.accordianHeader {
  padding: $spacer * 2;
  background-image: url($assets-path + "faq-grey.png");
  background-size: 100% 100%;
  &:hover { background-color: $faq-item-header-background-colour; }
}

// Post Content - CTAs
.postContent {

  a.cta-button.cta-blue {
    background-image: url($assets-path + "blue-button.png");
    background-size: 100.5% 100.5%;
    background-position: center;
    &:hover { background-image: url($assets-path + "blue-button-hover.png"); }
  }

  a.cta-button.cta-yellow {
    background-image: url($assets-path + "yellow-button.png");
    background-size: 100.5% 100.5%;
    background-position: center;

    &:hover { background-image: url($assets-path + "yellow-button-hover.png"); }
  }

  a.cta-button.cta-orange {
    background-image: url($assets-path + "orange-button.png");
    background-size: 100.5% 100.5%;
    background-position: center;

    &:hover { background-image: url($assets-path + "orange-button-hover.png"); }
  }

}


// Category - Remove share wrapper
body.PostCategory_page-no-share .shareWrapper {
  display: none;
}

// Subsite - Overall
// Subsite - Banner height
body.subsite .carouselSlide,
body.subsite .carouselSlides {
  max-height: 450px;
}

// Subsite mobile - Banner shade
@media (max-width: map-get($breakpoints, lg)) {
  .carouselSlideTitle:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0000008c;
  }
}
// Subsite nav
body.subsite .menuSub {
  position: relative;
  margin-top: -$spacer;
  background-color: transparent;
  padding: 0.25em 0.25em 0.5rem;
  background-image: url($assets-path + "submenu-teal.png");
  background-size: 100% 100%;
  @media (max-width: map-get($breakpoints, lg)) {
    background-image: url($assets-path + "mobile-submenu-teal.png");
  }
}

// Subsite Homepage - Title
body.subsite .headerTextSubsite { display: none; }
body.subsite h1.subsiteTitle {
  position: absolute;
  width: auto;
  left: 50%;
  bottom: 4vw;
  transform: translateX(-50%);
  padding: 1.5rem;
  background-color: rgba($_yellow, 0.0125%);
  color: $white;
  background-image: url($assets-path + "yellow-button.png");
  background-size: 100.5% 100.5%;
  background-position: center;
  z-index: 2;
  @media (max-width: map-get($breakpoints, lg)) {
    font-size: $font-size-large;
  }
}

// Subsite inner - Banner
body.subsite .headerTextSubsite,
.subsite:not(.homepage) .headerWrapperSubsite {
  display: block;
}

// Subsite inner - Page order
body.subsiteInner .post {
  .headerWrapper.headerWrapperSubsite { order: 1; }
  .menuSub { order: 3; }
  .headerTextSubsite {
    order: 2;
    position: relative;
    padding-top: 0;
    margin-bottom: 0;
  }
}

// Subsite body
body.subsite .subsiteBody {
  padding-top: $spacer * 1.5;
  padding-bottom: $spacer * 1.5;
}


// Appeal Action
.post .appealActionsWrapper {
  background-color: transparent;
}

// Appeal listing - Layout
body.appealsList .listing {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // Remove appeal tabs
  .tabsWrapper { display: none; }
  // Donor List - Restyle
  .donorsListWrapper {
    width: 100%;
    order: 5;
    margin-bottom: $spacer * 3;
  }
}


// Card image - border radius
.homeFeature, .feedItem, .listedPost {
  a[class*="image-link"] {
    border-radius: $card-image-border-radius;
  }
}

// Event button - background
.eventBookingWrapper {
  background-color: transparent;
}

// DES-4785
a.buttonIcon[class*="Quantity"],
a.eventEnquiry {
  background-color: $_orange;
}