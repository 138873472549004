// Config values
$platform-name: "llamaulimited";
$assets-path: "../assets/";

// Colours
  $_blue:  #186DA8;
  $_yellow: #FBBA00;
  $_orange: #EC6608;
  $_turquoise: #28939E;
  $_dark-grey: #343434;
  $_light-grey: #DADADA;

$brand-primary: $_blue;
$brand-primary-text-contrast-override: #FFF;
$brand-secondary: $_yellow;
$brand-secondary-text-contrast-override: #FFF;

$donate-colour: $_turquoise;

// Create greyscale
$black: #000;
$grey-dark: $_dark-grey;
$grey: #464a4c;
$grey-light: #636c72;
$grey-lighter: $_light-grey;
$grey-lightest: #E3E3E3;
$white: #FFF;

// Layout
$container-max-width: 1230px;
$gap-width: 30px;
$site-bleed: 20px;

$border-radius: 5px;
$border-colour: $grey-lightest;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Spacers
$spacer: 1rem;
$spacer-y: $spacer;
$spacer-x: $spacer;

// Misc
$body-bg: $white;
$body-colour: $grey-dark;
$profile-pics-enabled: true;
$border-width: 0px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.3s;
$transition-duration-slow: 0.3s;
$transition-curve: ease;

// Logo
$logo: "logo.png";
$logo-retina: $logo;
$logo-width: 500px;
$logo-height: 80px;
$logo-breakpoint: map-get($breakpoints, lg);
$logo-mobile-width: $logo-width * 0.6;
$logo-mobile-height: $logo-height * 0.6;

// Typography
$font-family-base: "Effra-Regular", sans-serif;
$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

$font-weight-normal: 400;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.45;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$headings-margin-top: 0.5em; // This will only affect headings within post content
$headings-margin-bottom: 0.5em;
$headings-font-family: 'Effra-Bold', sans-serif;
$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.1;
$headings-colour: inherit;
$headings-text-transform: none;
$headings-letter-spacing: initial;

$script-font-family: 'Chelsea Market';

$hr-border-colour: $border-colour;
$hr-border-width: $border-width;

// Links
$link-colour: $brand-primary;
$link-decoration: none;
$link-hover-decoration: underline;
$link-hover-style: darken;

// Icon font
$icon-font-family: "FontAwesome";

// Buttons
$btn-background-colour: rgba($_blue, 0.0125);
$btn-text-colour: $white;
$btn-border-radius: $border-radius;
$btn-border-width: 0;
$btn-border-colour: $btn-background-colour;
$btn-box-shadow: $box-shadow;
$btn-padding-x: 2em;
$btn-padding-y: 1em;
$btn-font-family: $headings-font-family;
$btn-font-weight: $font-weight-bold;
$btn-font-size: inherit;
$btn-text-transform: uppercase;
$btn-line-height: 1.25;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

// Donate button
$donate-btn-background-colour: rgba($_yellow, 0.0125);
$donate-btn-colour: $white;

// Social icons
$social-icons-background-colour: transparent;
$social-icons-colour: $body-colour;
$social-icons-hover-colour: $white;
$social-icons-width: 35px;
$social-icons-height: 35px;
$social-icons-font-size: 1.2rem;
$social-icons-gap: 5px;
$social-icons-border-radius: 35px;
$social-icons-box-shadow: $btn-box-shadow;

// Header specific
$social-icons-header-background-colour: $social-icons-background-colour; // A colour, transparent, or 'brand'
$social-icons-header-colour: $social-icons-colour; // A colour, or 'brand'
$social-icons-header-hover-colour: $social-icons-hover-colour; // A colour, or 'brand'

// Footer specific social icons
$social-icons-footer-background-colour: transparent;
$social-icons-footer-colour: $white;
$social-icons-footer-hover-colour: $_turquoise;

// Share this page
$share-enabled: true;
$share-text-align: left; // left, center, right
$share-label-font-family: $font-family-base;
$share-label-font-size: $font-size-h5;
$share-label-font-colour: $body-colour;

// Form elements
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-line-height: 1.25;
$input-colour: $body-colour;
$input-border-colour: #ced4da;
$input-border: 1px solid $input-border-colour;
$input-border-radius: $border-radius;
$input-placeholder-colour: $grey-light;

// Field groups
$field-group-label-width: 280px;

// Tables
$table-cell-padding: 0.75rem;

// Card
$card-gap-width: $gap-width;
$card-border-radius: $border-radius;
$card-border: 0px solid $border-colour;
$card-box-shadow: $box-shadow;
$card-text-align: left;
$card-image-on: true;
$card-image-opacity: 1;
$card-image-background-colour: $brand-primary;
$card-image-padding: 0;
$card-image-border-radius: $border-radius;
$card-details-padding: $spacer 0;
$card-details-background-colour: transparent;
$card-details-background-gradient: none; // none or CSS gradient
$card-heading-font-family: $headings-font-family;
$card-heading-text-transform: none;
$card-heading-colour: $body-colour;
$card-heading-font-size: $font-size-h4;
$card-heading-margin-bottom: $spacer / 2;
$card-summary-enabled: true;
$card-summary-colour: $body-colour;
$card-summary-font-size: $font-size-base;
$card-footer-on: false;
$card-footer-background-colour: $card-details-background-colour;
$card-footer-background-gradient: none; // none or CSS gradient
$card-footer-colour: $body-colour;
$card-footer-padding: 0 $card-details-padding;
$card-min-width: 280px;

// Card basic
$card-basic-image-position: above;

// Card text overlay
$card-text-overlay-show-summary-on-hover: true;
$card-text-overlay-summary-always-on: false;
$card-text-overlay-details-max-width: 100%;
$card-text-overlay-details-position-y: bottom; // top, center, bottom
$card-text-overlay-details-position-x: left; // left, center, right

// Card text over
$card-text-over-image-vertical-text-position: center; // top, center or bottom
$card-text-over-image-darken-image-amount: 0.5;
$card-text-over-image-tint-colour: $black;
$card-text-over-image-show-summary-on-hover: true;

// Card side by side
$card-side-by-side-image-position: left;
$card-side-by-side-image-width: 35%;
$card-side-by-side-grow-image: true;
$card-side-by-side-vertical-text-position: top;
$card-side-by-side-breakpoint: map-get(
  $breakpoints,
  md
); // When do you want to break the text under image?

// Card hover state
$card-hover-border-colour: transparent;
$card-hover-box-shadow: $card-box-shadow;
$card-hover-image-opacity: 0.75;
$card-hover-image-scale: 1.025;
$card-hover-details-background-colour: transparent;
$card-hover-heading-colour: $card-heading-colour;
$card-hover-summary-colour: $body-colour;

// Event cards
$event-card-floating-date: false;
// $event-card-floating-date-background-colour: $card-details-background-colour;
// $event-card-floating-date-colour: text-contrast($event-card-floating-date-background-colour);
// $event-card-floating-date-border-radius: $border-radius;
// $event-card-floating-date-margin-y: $spacer;
// $event-card-floating-date-margin-x: $spacer;

// Menu admin
$menu-admin-enabled: true;
// $menu-admin-max-width: 100% !default; // $container-max-width
// $menu-admin-background-colour: $grey-lighter !default;
// $menu-admin-contents-max-width: $container-max-width !default; // 100%
// $menu-admin-font-size: $font-size-small !default;
// $menu-admin-align-items: right !default; // left, center, right
// $menu-admin-link-colour: $link-colour !default;
// $menu-admin-absolute: false !default;
// $menu-admin-login-link-enabled: true !default;
// $menu-admin-manager-link-enabled: true !default;
// $menu-admin-my-details-link-enabled: true !default;
// $menu-admin-logout-link-enabled: true !default;
$menu-admin-to-nav-breakpoint: 8000px;

// Header
$header-background-colour: $white;

// Header content
// $header-content-max-width: $container-max-width !default;
$header-content-margin-top: $spacer * .75;
$header-content-margin-bottom: $spacer * .75;
$header-content-padding-top: $spacer * .75;
$header-content-padding-bottom: $spacer * .75;
// $header-content-padding-left: $site-bleed !default;
// $header-content-padding-right: $site-bleed !default;

// Tagline
// $tagline-enabled: false !default;
// $tagline-font-family: $headings-font-family !default;
// $tagline-font-weight: $font-weight-bold !default;
// $tagline-font-size: $font-size-h5 !default;
// $tagline-colour: $headings-colour !default;
// $tagline-above-cta-breakpoint: 0 !default;
// $tagline-breakpoint: map-get($breakpoints, md) !default;

// Main call to action
// $cta-btn-background-colour: $btn-background-colour !default;
// $cta-btn-colour: $btn-text-colour !default;
// $cta-btn-gap: 5px !default;
// $cta-padding-right-at-nav-breakpoint: 6.5em !default;
$cta-btn-breakpoint: map-get($breakpoints, md);
// $cta-donate-on-appeal: false !default;

// Search
// $header-search-enabled: true !default;
$header-search-margin-right: 15px;
$header-search-breakpoint: map-get($breakpoints, lg);

// Search - input
$header-search-input-max-width: 150px;
// $header-search-input-height: initial !default;
$header-search-input-margin-right: -40px;
// $header-search-input-padding: $input-padding-y $input-padding-x !default;
$header-search-input-placeholder-colour: $body-colour;
$header-search-input-border-radius: $border-radius $border-radius 0 0;
$header-search-input-border-colour: $body-colour;
$header-search-input-border-width: 0 0 1.5px 0;
$header-search-input-background-colour: transparent;
$header-search-input-colour: $body-colour;
// $header-search-input-focus-background-colour: $white !default;
$header-search-input-focus-colour: $body-colour;

// Search - button
// $header-search-button-width: 40px !default;
// $header-search-button-height: 40px !default;
$header-search-button-background-colour: transparent;
// $header-search-button-icon-font-size: $social-icons-font-size !default;
$header-search-button-icon-colour: $body-colour;
// $header-search-button-border-radius: $border-radius !default;

// Search when in nav
// $nav-search-enabled: $header-search-enabled !default;
$nav-search-input-margin-right: auuto;
// $nav-search-input-padding: $input-padding-y $input-padding-x !default;
$nav-search-input-placeholder-colour: $body-colour;
$nav-search-input-border-colour: transparent;
$nav-search-input-border-width: 0 0 0 0;
$nav-search-input-background-colour: $white;
$nav-search-button-background-colour: transparent;
$nav-search-button-icon-colour: $body-colour;

// Social icons
$header-social-icons-margin-right: 15px;
// $header-social-icons-margin-bottom: 0 !default;
// $header-social-icons-above-cta-buttons: false !default;
$header-social-icons-breakpoint: map-get($breakpoints, md);

// Sticky header
$header-sticky-enabled: true;
$header-sticky-background-colour: $white;
// $header-sticky-box-shadow: 0 0 10px rgba($black, 0.1) !default;
// $header-sticky-logo: $logo !default;
// $header-sticky-logo-retina: $header-sticky-logo !default;
$header-sticky-logo-width: $logo-width;
$header-sticky-logo-height: $logo-height;
// $header-sticky-menu-admin-enabled: false !default;
$header-sticky-search-enabled: true;
$header-sticky-social-icons-enabled: true;
// $header-sticky-cta-btns-enabled: true !default;
$header-sticky-header-content-margin-top: $header-content-margin-top;
$header-sticky-header-content-margin-bottom: $header-content-margin-bottom;
// $header-sticky-nav-margin-bottom: 0 !default;

// Navigation
$nav-type: normal !default; // normal, flyout, fullscreen // TODO: mega
$nav-font-family: $headings-font-family;
$nav-breakpoint: map-get($breakpoints, lg);
$nav-background-colour: rgba($_light-grey, 0.0125);
// $nav-text-transform: none !default;

// Top level items
$nav-top-level-item-padding: 12.5px 20px 10px;
$nav-top-level-item-colour: $_dark-grey;
// $nav-top-level-item-font-family: $nav-font-family !default;
// $nav-top-level-item-font-size: $font-size-base !default;
$nav-top-level-item-font-weight: $font-weight-bold;
$nav-top-level-item-background-colour: transparent !default;
$nav-top-level-item-hover-colour: $_blue;
$nav-top-level-item-hover-background-colour: transparent;
// $nav-top-level-chevrons-enabled: false !default;
// $nav-top-level-chevrons-colour: $nav-top-level-item-colour !default;

// Submenus
// $nav-submenu-width: 220px !default;
$nav-submenu-background-colour: rgba($_light-grey, 0.0125);
// $nav-submenu-box-shadow: none !default;
// $nav-submenu-border-radius: $border-radius !default;
// $nav-submenu-item-padding: 10px 20px !default;
$nav-submenu-item-colour: $_dark-grey;
// $nav-submenu-item-font-family: $nav-font-family !default;
// $nav-submenu-item-font-size: $font-size-base !default;
$nav-submenu-item-font-weight: $font-weight-bold;
$nav-submenu-item-hover-colour: $_blue;
$nav-submenu-item-hover-background-colour: rgba($black, 0.025);
// $nav-submenu-chevrons-enabled: true !default;
// $nav-submenu-chevrons-colour: $nav-submenu-item-colour !default;

// Burger button
$burger-btn-background-colour: rgba($_light-grey, 0.0125);
$burger-btn-text-colour: $_dark-grey;
// $burger-btn-text-transform: $btn-text-transform !default;
// $burger-btn-icon-breakpoint: null !default;
$burger-btn-bar-breakpoint: map-get($breakpoints, lg);

// Nav normal
$nav-normal-max-width: 100%;
// $nav-normal-margin-top: 0 !default;
$nav-normal-margin-bottom: 0;
// $nav-normal-contents-max-width: $container-max-width !default;
$nav-normal-align-items: center;
$nav-normal-border-radius: 0;
$nav-normal-mobile-background-colour: $_light-grey;
$nav-normal-mobile-top-level-item-colour: $_dark-grey;
// $nav-normal-mobile-submenu-background-colour: rgba($black, 0.08) !default;
// $nav-normal-mobile-submenu-item-colour: text-contrast($nav-normal-mobile-background-colour) !default;
// $nav-normal-mobile-chevrons-enabled: true !default;
// $nav-normal-mobile-chevrons-colour: text-contrast($nav-normal-mobile-background-colour) !default;

// Nav - Parent nav items clickable

// // Specifically when no banner present and you have gone for $header-absolute: true

// Carousel
$carousel-max-width: 100%;
$carousel-border-radius: 0;
// $carousel-margin-top: 0 !default;
// $carousel-margin-bottom: 0 !default;
$carousel-image-overlay: rgba($black, 0.55);
$carousel-contents-max-width: $container-max-width;
// $carousel-breakpoint: map-get($breakpoints, lg) !default;
// $carousel-details-style: overlay !default; // overlay, adjacent or below
$carousel-details-position-y: center;
$carousel-details-position-x: left;
$carousel-details-background-colour: transparent;
// $carousel-details-box-shadow: $box-shadow !default;
$carousel-details-max-width: 750px;
// $carousel-details-min-height: auto !default;
// $carousel-details-padding: $spacer !default;
// $carousel-details-margin-y: 0px !default;
// $carousel-details-margin-x: 0px !default;
// $carousel-details-border-radius: $border-radius !default;
// $carousel-details-text-align: left !default;
$carousel-heading-text-transform: uppercase;
$carousel-heading-colour: $white;
$carousel-heading-font-size: $font-size-h2;
$carousel-summary-enabled: false;
// $carousel-summary-colour: text-contrast(
//   $carousel-details-background-colour
// ) !default;
// $carousel-summary-font-size: inherit !default;
$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: rgba($_orange, 0.0125);
$carousel-read-more-border-colour: transparent;
$carousel-read-more-colour: $white;
// $carousel-read-more-font-size: $carousel-summary-font-size !default;

// Carousel controls (left-right buttons)
$carousel-controls-enabled: true;
// $carousel-controls-button-size: 40px !default;
// $carousel-controls-button-gap: 0px !default;
$carousel-controls-button-background-colour: transparent;
// $carousel-controls-button-border-radius: $border-radius !default;
// $carousel-controls-button-box-shadow: $box-shadow !default;
$carousel-controls-position-y: center;
$carousel-controls-position-x: split;
// $carousel-controls-margin-y: 0px !default;
$carousel-controls-margin-x: 5px;
// $carousel-controls-icon-colour: $btn-text-colour !default;
$carousel-controls-icon-font-size: 1.45em;


// Carousel buttons (dots or tabs)
$carousel-buttons-enabled: false;

// Carousel buttons dots

// Carousel buttons tabs

// Carousel video play button (before breakpoint, when carousel details present)

// Carousel scroll button

// Carousel background YouTube video

// Carousel after breakpoint
$carousel-mobile-details-background-colour: $white;
$carousel-mobile-heading-colour: $_blue;

// Banner min-height under sm breakpoint - helps to keep some vertical height at narrow viewports, especially for letter-box aspect ratios
$banner-min-height: 1px;

// Home intro
// $home-intro-max-width: 100% !default;
$home-intro-background-colour: rgba($_yellow, 0.0125);
// $home-intro-background-gradient: none !default;
// $home-intro-background-image: "home-intro.png";
// $home-intro-background-image-opacity: 1;
// $home-intro-box-shadow: $box-shadow !default;
$home-intro-content-max-width: 900px;
// $home-intro-content-align: center !default;
$home-intro-padding-y: $spacer * 5;
$home-intro-margin-top: 0;
// $home-intro-margin-bottom: 0 !default;
// $home-intro-text-align: center !default;
$home-intro-text-transform: none;
// $home-intro-font-family: $headings-font-family !default;
$home-intro-font-size: $font-size-h2;
// $home-intro-font-weight: $headings-font-weight !default;
$home-intro-line-height: 1.45;
$home-intro-colour: $white;
$home-intro-border-radius: 0;

// Home headlines intro
$home-headlines-intro-enabled: true;
$home-headlines-intro-snippet-title-enabled: true;
// $home-headlines-intro-max-width: 100% !default;
// $home-headlines-intro-content-max-width: $container-max-width !default;
$home-headlines-intro-background-colour: transparent;
$home-headlines-intro-padding-top: $spacer * 2.5;
// $home-headlines-intro-padding-bottom: 0 !default;
$home-headlines-intro-margin-top: $spacer * 2.5;
// $home-headlines-intro-margin-bottom: 0 !default;
$home-headlines-intro-text-align: left;
$home-headlines-intro-text-transform: $headings-text-transform;
// $home-headlines-intro-font-family: $headings-font-family !default;
$home-headlines-intro-font-size: $font-size-h1;
$home-headlines-intro-colour: $body-colour;

// Home features
// $home-features-max-width: $container-max-width !default;
$home-features-background-colour: transparent;
$home-features-padding-top: $spacer;
$home-features-padding-bottom: $spacer * 6.5;


// Home feeds
// $home-feeds-max-width: $container-max-width !default;
$home-feeds-background-colour: $grey-lightest;
$home-feeds-padding-top: $spacer * 6.5;
$home-feeds-padding-bottom: $spacer * 6.5;
// $home-feeds-cards-entry-animation-prefab: 0 !default;
// $home-feeds-cards-entry-animation-stagger: true !default;
// $home-feeds-cards-entry-animation-stagger-count: 3 !default;

// Feeds title
// $feeds-title-font-family: $headings-font-family !default;
// $feeds-title-font-size: $font-size-h2 !default;
// $feeds-title-colour: text-contrast($home-feeds-background-colour) !default;
// $feeds-title-text-align: left !default; // left, center, right

// Feed item
// $feed-item-read-more-enabled: false !default;

// Event feed items
$feed-item-event-start-date-enabled: true;
$feed-item-event-location-enabled: false;


// Footer
//

$footer-boxes-count: 5;
$footer-prefab: 4;
// $footer-max-width: 100% !default;
// $footer-contents-max-width: $container-max-width !default;
// $footer-margin-y: 0 !default;
$footer-padding-y: $spacer * 5;
$footer-background-colour: $_turquoise;
// $footer-background-gradient: none !default; // none or CSS gradient
$footer-colour: $white;
$footer-link-colour: $white;
$footer-link-decoration: none;
// $footer-link-hover-colour: darken($footer-link-colour, 10%) !default;
// $footer-link-hover-decoration: underline !default;
// $footer-text-align: left !default;
// $footer-font-size: inherit !default;
$footer-headings-font-size: $font-size-h4;

// Footer admin links
// $footer-admin-basket-link-enabled: true !default;
// $footer-admin-login-link-enabled: true !default;
// $footer-admin-manager-link-enabled: true !default;
// $footer-admin-my-details-link-enabled: true !default;
// $footer-admin-logout-link-enabled: true !default;

// Newsletter
$newsletter-background-colour: transparent;
// $newsletter-background-gradient: none !default; // none or CSS gradient
$newsletter-colour: $white;
// $newsletter-heading-font-family: $headings-font-family !default;
$newsletter-heading-font-size: $footer-headings-font-size;
$newsletter-heading-text-align: left;
// $newsletter-heading-text-transform: $headings-text-transform !default;
// $newsletter-heading-margin-bottom: $spacer * 2 !default;
$newsletter-heading-colour: $body-colour;
// $newsletter-input-background-colour: $white !default;
$newsletter-button-height: 44px;
$newsletter-button-background-colour: rgba($_orange, 0.0125);
$newsletter-button-colour: $white;
// $newsletter-button-border-colour: $brand-secondary !default;
$newsletter-use-placeholders: true;

// Context container
$context-container-background-colour: #F0F0F0;
$context-container-padding: $spacer;
$context-container-border-radius: $border-radius;

// Donors list
// $donors-list-enabled: true !default;
// $donors-list-profile-pics-enabled: $profile-pics-enabled !default;

// Posts
$post-content-max-width: 980px;
$post-body-background-colour: transparent;

// Header text (container for breadcrumb and page title)
// $header-text-margin-top: $spacer * 3 !default;
// $header-text-margin-bottom: $spacer * 1.5 !default;

// Page title
// $page-title-font-size: $font-size-h1 !default;
// $page-title-font-family: $headings-font-family !default;
// $page-title-font-weight: $headings-font-weight !default;
$page-title-colour: $_orange;
// $page-title-text-transform: $headings-text-transform !default;
// $page-title-text-align: left !default; // left, center or right
// $page-title-margin-top: 0 !default;
// $page-title-margin-bottom: $headings-margin-bottom !default;
// $page-title-md-font-size: $page-title-font-size * .8 !default; // <= $breakpoints.md
// $page-title-sm-font-size: $page-title-font-size * .6 !default; // <= $breakpoints.sm

// Page title over banner
// $page-title-over-banner-enabled: false !default;
// $page-title-over-banner-breakpoint: map-get($breakpoints, md);
// $page-title-over-banner-background-colour: $brand-primary !default; // Add a background colour so that title is always legible as it appears over a photo
// $page-title-over-banner-colour: text-contrast($page-title-over-banner-background-colour) !default;
// $page-title-over-banner-breadcrumb-enabled: true !default; // Toggle breadcrumbs - this CAN work but also some people might think looks a bit weird

// Sidebar
$sidebar-enabled: false;
$sidebar-layout: adjacent !default; // adjacent, below or above - where do you want the sidebar to be positioned on posts?
$sidebar-adjacent-width: 300px;
$sidebar-adjacent-gap: $spacer * 4;
$sidebar-background-colour: rgba($_yellow, 0.0125);
$sidebar-padding: $spacer;

// Associated lists
$associated-latest-enabled: true !default;
$associated-related-enabled: true !default;
$associated-most-read-enabled: true !default;
$associated-tag-cloud-enabled: false !default;
$associated-max-number-of-posts: 4 !default;
$associated-list-heading-font-size: $font-size-h3 !default;
$associated-item-image-enabled: false !default;
$associated-item-heading-font-size: $font-size-base !default;
$associated-item-heading-colour: $white;
$associated-item-headings-text-transform: none;
$associated-item-summary-enabled: false !default;
$associated-item-border: false;

// Listing
$listing-content-max-width: $container-max-width !default;
$listing-intro-max-width: $listing-content-max-width !default;
$listing-body-background-colour: transparent !default;
$listing-cards-per-row-desktop: 3 !default;
$listing-cards-per-row-tablet: 2 !default;
$listing-card-min-width: $card-min-width !default; // Either a px value or 100%. Adjust this value to dictate how many listing items you want in a single row

// Listed post settings
$listed-post-read-more-enabled: false !default;
$listed-news-post-publish-date-enabled: true !default;
$listed-blog-post-publish-date-enabled: true !default;
$listed-blog-post-author-enabled: true !default;
$listed-event-post-start-date-enabled: true !default;
$listed-event-post-location-enabled: true !default;
$listed-appeal-totaliser-enabled: true !default;

// Breadcrumb
// $breadcrumb-enabled: true !default;
// $breadcrumb-disabled-on-listing: false !default; // Opt out of showing breadcrumbs on listing pages, if breadcrumbs are enabled
// $breadcrumb-margin-top: $spacer !default;
// $breadcrumb-margin-bottom: $spacer !default;
// $breadcrumb-max-width: $post-content-max-width !default;
// $breadcrumb-text-align: $page-title-text-align !default; // left, center or right

// Blockquote
// $blockquote-max-width: $post-content-max-width !default; // Either a px value or 100%
$blockquote-background-colour: transparent;
// $blockquote-background-gradient: none !default; // none or CSS gradient
$blockquote-border-radius: 0;
$blockquote-margin-y: $spacer * 2;
$blockquote-padding: $spacer * 2;
$blockquote-text-align: center;
$blockquote-font-size: 1.5em;
$blockquote-font-family: $script-font-family;
$blockquote-font-weight: $headings-font-weight;
$blockquote-line-height: 1.4;
$blockquote-colour: inherit;

// Fundraising
// $fundraising-enabled: true !default; // Quickly turn off fundraising features

// Find a fundraiser widget
// $find-a-fundraiser-widget-enabled: true !default;
// $find-a-fundraiser-profile-pics-enabled: $profile-pics-enabled !default;

// Top fundraisers
// $top-fundraisers-enabled: true !default;
// $top-fundraisers-profile-pics-enabled: $profile-pics-enabled !default;

// Donation form
$donation-form-layout: rows;
$donation-form-banner-enabled: false;
$donation-form-frequency-tabs: true;

// Donation amounts
// $donation-amount-min-width: $card-min-width !default; // IF using columns layout, set a min-width value on the amounts to dictate how many columns you want. Default will be 3 (items grow to fill row)
// $donation-amount-figure-font-size: $font-size-h2 !default;
// $donation-amount-figure-colour: $donate-colour !default;
// $donation-amount-thumbnail-enabled: true !default;

// Quick giving panel
// $quick-giving-type: rows;
// $quick-giving-background-colour: $context-container-background-colour !default;
// $quick-giving-background-gradient: none !default; // none or CSS gradient
// $quick-giving-padding: $context-container-padding !default;
// $quick-giving-border-radius: $context-container-border-radius !default;
// $quick-giving-donation-amount-figure-font-size: $donation-amount-figure-font-size *
//   0.75 !default;
// $quick-giving-donation-amount-padding: 0.5rem 1rem !default;
// $quick-giving-donation-amount-background-colour: $donate-colour !default;
// $quick-giving-donation-amount-figure-colour: text-contrast(
//   $quick-giving-donation-amount-background-colour
// ) !default;
// $quick-giving-donation-amount-border-width: $btn-border-width !default;
// $quick-giving-donation-amount-border-colour: $donate-colour !default;
// $quick-giving-donation-amount-selected-background-colour: $white !default;
// $quick-giving-donation-amount-selected-figure-colour: text-contrast(
//   $quick-giving-donation-amount-selected-background-colour
// ) !default;

// Home quick giving panel
// $home-quick-giving-enabled: true !default;
// $home-quick-giving-type: simple !default; // simple, adjacent or overlay
// $home-quick-giving-max-width: 100% !default;
// $home-quick-giving-content-max-width: 100% !default;
// $home-quick-giving-margin-top: 0 !default;
// $home-quick-giving-margin-bottom: 0 !default;
// $home-quick-giving-padding-y: $spacer * 5 !default;
// $home-quick-giving-background-colour: $quick-giving-background-colour !default;
// $home-quick-giving-background-gradient: $quick-giving-background-gradient !default; // none or CSS gradient
// $home-quick-giving-background-image: null !default;
// $home-quick-giving-background-image-opacity: 0.5 !default;
// $home-quick-giving-donation-amount-background-colour: $donate-colour !default;
// $home-quick-giving-donation-amount-figure-colour: text-contrast(
//   $home-quick-giving-donation-amount-background-colour
// ) !default;
// $home-quick-giving-donation-amount-border-width: $btn-border-width !default; // Only comes into play with transparent background
// $home-quick-giving-donation-amount-border-colour: $donate-colour !default;
// $home-quick-giving-donation-amount-selected-background-colour: $white !default;
// $home-quick-giving-donation-amount-selected-figure-colour: text-contrast(
//   $home-quick-giving-donation-amount-selected-background-colour
// ) !default;
// $home-quick-giving-donate-btn-background-colour: $btn-background-colour !default;
// $home-quick-giving-donate-btn-colour: $btn-text-colour !default;
// $home-quick-giving-donate-btn-border-colour: $btn-border-colour !default;
// $home-quick-giving-donate-btn-font-size: $quick-giving-donation-amount-figure-font-size !default;
// $home-quick-giving-colour: $body-colour !default; // This should cover any headings and text you have in the snippet
// $home-quick-giving-heading-colour: $headings-colour !default;
// $home-quick-giving-heading-font-size: $font-size-h2 !default;
//
// // Adjacent homepage quick giving
// $home-quick-giving-type-adjacent-image-side: left !default; // If type is adjacent, which side do you want the image
//
// // Overlay homepage quick giving
// $home-quick-giving-type-overlay-items-side: left !default; // If type is overlay, which side do you want the copy and donation items?
// $home-quick-giving-type-overlay-gradient: linear-gradient(
//   to left,
//   rgba(0, 0, 0, 0) 40%,
//   rgba(0, 0, 0, 0.75) 100%
// ) !default;

// Totalisers
// $totaliser-enabled: true !default;
$totaliser-layout: vertical;
// $totaliser-width: 60px !default;
// $totaliser-panel-background-colour: transparent !default;

// Comments
// $comment-add-image-enabled: true !default;
// $comment-add-video-enabled: true !default;

// Blog details
$blog-details-enabled: true !default;
$blog-details-background-colour: $context-container-background-colour !default;
$blog-details-padding: $context-container-padding !default;
$blog-details-border-radius: $context-container-border-radius !default;
$blog-details-profile-pic-enabled: $profile-pics-enabled !default;

// FAQs
// $faq-content-max-width: $post-content-max-width !default;
$faq-item-header-background-colour: rgba($context-container-background-colour, 0.0125%);
// $faq-item-header-padding: $context-container-padding !default;
// $faq-item-header-border-radius: $context-container-border-radius !default;
$faq-item-header-font-size: $font-size-h3;
// $faq-item-btn-background-colour: $btn-background-colour !default;
// $faq-item-btn-chevron-colour: $btn-text-colour !default;
// $faq-item-hover-btn-background-colour: button-hover-state(
//   $faq-item-btn-background-colour
// ) !default;
// $faq-item-hover-btn-chevron-colour: text-contrast(
//   $faq-item-hover-btn-background-colour
// ) !default;

// Shop
// $shop-enabled: true !default; // Quickly disable shop on the platform (hide basket and not include relevant Sass for compilation)

// $basket-link-only-on-shop-pages: false !default; // Set to true to only show the basket link (in both header and footer admin menus) on 'shop' related pages only

// Departments list
// $department-list-width: $sidebar-adjacent-width !default;
// $department-list-background-colour: $context-container-background-colour !default;
// $department-list-padding: $context-container-padding !default;
// $department-list-border-radius: $context-container-border-radius !default;

// Product-price
$product-price-colour: $_orange;
// $product-price-font-size: $font-size-h4 !default;

// Product post
// $product-post-content-max-width: $post-content-max-width !default;

// Subsite
$subsite-enabled: true;
$subsite-hide-main-header: false;
$subsite-hide-main-tagline: false;
$subsite-hide-header-search: false;
$subsite-hide-main-cta: false;
// $subsite-content-max-width: $container-max-width !default;
// $subsite-title-font-size: $page-title-font-size !default;
//
// // Subsite logo
$subsite-logo: 'title';
// $subsite-logo-retina: $subsite-logo !default;
// $subsite-logo-width: 120px !default;
// $subsite-logo-height: 120px !default;
// $subsite-logo-margin: $header-content-margin-top 0 0 !default;

// Subsite nav
$subsite-nav-breakpoint: $nav-breakpoint;
$subsite-nav-margin-top: 0;
$subsite-nav-margin-bottom: $spacer;
$subsite-nav-background-colour: rgba($_turquoise, 0.0125);
$subsite-nav-contents-max-width: $container-max-width;
$subsite-nav-align-items: center;
$subsite-nav-top-level-item-colour: text-contrast($subsite-nav-background-colour);
$subsite-nav-top-level-item-background-colour: transparent;
$subsite-nav-top-level-item-hover-colour: $subsite-nav-top-level-item-colour;
$subsite-nav-top-level-item-hover-background-colour: rgba($black, 0.05);
$subsite-nav-top-level-item-padding: 20px 20px 15px;
//
// $subsite-nav-submenu-width: $nav-submenu-width !default;
// $subsite-nav-submenu-background-colour: $nav-submenu-background-colour !default;
// $subsite-nav-submenu-box-shadow: $nav-submenu-box-shadow !default;
// $subsite-nav-submenu-border-radius: $nav-submenu-border-radius !default;
// $subsite-nav-submenu-item-padding: $nav-submenu-item-padding !default;
// $subsite-nav-submenu-item-colour: $nav-submenu-item-colour !default;
// $subsite-nav-submenu-item-font-family: $nav-submenu-item-font-family !default;
// $subsite-nav-submenu-item-font-size: $nav-submenu-item-font-size !default;
// $subsite-nav-submenu-item-font-weight: $nav-submenu-item-font-weight !default;
// $subsite-nav-submenu-item-hover-colour: $nav-submenu-item-hover-colour !default;
// $subsite-nav-submenu-item-hover-background-colour: $nav-submenu-item-hover-background-colour !default;

// Cookie consent
$cookie-consent-position: bottom !default; // Bottom or top
$cookie-consent-background-colour: $black !default; // You can use rgba here for opacity
$cookie-consent-colour: text-contrast(
  $cookie-consent-background-colour
) !default;
$cookie-consent-btn-background-colour: $btn-background-colour !default;
$cookie-consent-btn-text-colour: $btn-text-colour !default;
